import React, { useState, useCallback } from 'react';
import './App.css';
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim';
import logoImage from './logo.png'
function App() {
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [spinResult, setSpinResult] = useState(null);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);

  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
}, []);

const particlesLoaded = useCallback(async container => {
    await console.log(container);
}, []);
  

  const fetchOrdersByUsername = async () => {
    setLoading(true);

    try {
      // Simulate a delay for a couple of seconds
      await new Promise((resolve) => setTimeout(resolve, 2000));

      const response = await fetch('https://botum-6ea16f5d8e73.herokuapp.com/spin/check', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });


      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message);
      }

      setSpinResult(data.spinResult);
    } catch (error) {
      if (error.message === 'No eligible orders found') {
          setError('បងអត់មានបានកុម្មង់លើស 38$ ទេ');
      } else {
          setError(error.message);
      }
    } finally {
      // Add a slight delay to the loading spinner for a smooth transition
      setTimeout(() => setLoading(false), 500);
    }
  };

  const renderSpinResult = () => {
    if (loading) {
      return (
        <div className="loading-spinner animate-spin h-16 w-16 border-t-4 border-yellow-500 border-solid rounded-full"></div>
      );
    }

    if (spinResult) {
      // Display the actual spin result received from the backend
      return `Congratulations! You won: ${spinResult}`;
    }

    if(!error){
      return 'Press "Claim Reward" to spin the wheel!';
    }
  };

  return (
    <div className="min-h-screen bg-red-500 p-8 flex flex-col items-center ">
      <div className='flex justify-center items-center flex-col'>
          <img src={logoImage} alt="Logo" className="w-2/4 lg:w-1/4 md:w-1/4" style={{marginBottom: '-7%'}}/>
      </div>      
      <h1 className='text-center font-bold text-yellow-400 text-4xl mb-4'>Happy Chinese New Year</h1>
      <header className="bg-white p-8 rounded-lg shadow-lg w-full lg:w-1/2 md:w-full">
      <Particles
            id="tsparticles"
            className='z-[-1]'
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: {
                    enable: false,
                    mode: "push",
                  },
                  onHover: {
                    enable: false,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#ffff00", // Yellow color
                },
                links: {
                  enable: false, // Disable links
                },
                move: {
                  direction: "bottom", // Move downwards
                  enable: true,
                  outModes: {
                    default: "out", // Change to 'out' so they fall out of view
                  },
                  random: false,
                  speed: 2, // Adjust speed as needed
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 30,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 5 },
                },
              },
              detectRetina: true,
            }}
            
        />

        <div className="">
          <label className="block text-lg text-gray-700 mb-2 text-center">
            Enter username:
            </label>

            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="border rounded-md p-2 w-full mb-2"
            />
          <button
            onClick={fetchOrdersByUsername}
            disabled={loading}
            className="bg-yellow-500 w-full hover:bg-yellow-600 transition text-white font-semibold py-2 px-4 rounded-md"
          >
            {loading ? 'Fetching...' : 'Claim Reward'}
          </button>

        </div>

        <div className='flex items-center justify-center flex-col'>
          <h2 className="text-xl font-semibold mb-2 mt-2">Spin Result:</h2>
          {error ? <p className="text-red-500 text-lg">{error}</p> : null}
          <p className="text-lg text-center">{renderSpinResult()}</p>
        </div>
      </header>
    </div>
  );
}

export default App;
